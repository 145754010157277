import { useRoutes } from "react-router-dom";
import { Dashboard } from "../views/dashboard";
//import { NotFound } from "../views/notFound";

export const Routes = () => {
  const element = useRoutes(routes);
  return element;
};

const routes = [
   {
    element: <Dashboard />,
    path: "/",
  }
  /* { path: "*", element: <NotFound /> }, */
];
